const Headliner = () => {
    return (
        <div>
            <h1 className="headline-main">
                Our Products
            </h1>
        </div>
    )
}

export default Headliner;