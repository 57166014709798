const ProductsHeadline = () => {
    return (
        <div>
            <h1 className="headline-main">
                Lost Loop Merch
            </h1>
        </div>
    );
};

export default ProductsHeadline;