const SectionBeef = () => {
    return (
        <div>
            <h1 className="amount-head">
                Amounts we Offer
            </h1>
        </div>
    );
};

export default SectionBeef;